import { graphql, Link, StaticQuery } from "gatsby"
import React, { useState } from "react"
import { Container, Row } from "react-bootstrap"
import CarouselComponent from "../../components/common/carousel-component"
import CTA from "../../components/common/CTA"
import Frame from "../../components/common/frame"
import Hero from "../../components/common/Hero/Hero"
import PopUpForm from "../../components/common/PopUpForm"
import SVGIcon from "../../components/common/SVGIcon"
import Navigation from "../../components/navigation"
import Slide from "../../components/utility/slide_content"
import "../../styles/component/faq.scss"
import "../../styles/component/pricing-faq.scss"
import "../../styles/pages/partners.scss"
import "../../styles/pages/resellers.scss"
import "../../styles/pages/roadmap.scss"
import "../../styles/pages/tech-partnership.scss"

const Partners = () => {
  const [popupVisibility, setPopupVisibility] = useState(false)
  const togglePopup = () => {
    setPopupVisibility(!popupVisibility)
  }
  const formdata = [
    {
      name: "firstname",
      type: "text",
      label: "Name",
      errorMessage: "Please enter a name",
    },
    {
      name: "jobtitle",
      type: "text",
      label: "Job title",
      errorMessage: "Please enter a job title",
    },
    {
      name: "email",
      type: "email",
      label: "Email address",
      errorMessage: "Please enter a valid email",
    },
    {
      name: "company",
      type: "text",
      label: "Company name",
      errorMessage: "Please enter a company name",
    },

    {
      name: "mobilephone",
      type: "phone",
      label: "Mobile number",
      errorMessage: "Please enter a mobile number",
    },
    {
      name: "partnership_type",
      type: "radio",
      title: "Which partnership program are you interested in?",
      choices: ["Technology Partners", "Resellers", "Affiliates"],
      data: ["Technology Partners", "Resellers", "Affiliates"],
      errorMessage: "Please select a partnership program",
    },
  ]

  const responsiveSpeaker = {
    mobile2: {
      breakpoint: { max: 1175, min: 900 },
      items: 2,
    },
    mobile3: {
      breakpoint: { max: 900, min: 425 },
      items: 1.4,
    },
    mobile4: {
      breakpoint: { max: 425, min: 0 },
      items: 1,
    },
  }

  return (
    <>
      <StaticQuery
        query={graphql`
          query partners {
            SuperOps {
              pages(where: { title: "Partners" }) {
                title
                subtitle
                seo {
                  title
                  description
                  image {
                    url
                  }
                }
                newField {
                  ... on SuperOps_Hero {
                    tag
                    backgroundColor
                    heroHeading: heading {
                      text
                      html
                    }
                    heroExcerpt
                    heroImage {
                      url
                    }
                    primaryButtonText
                    primaryButtonLink
                  }
                  ... on SuperOps_Card {
                    __typename
                    cardName
                    title
                    heading
                    text
                    subtext
                    image {
                      url
                    }
                    color {
                      hex
                    }
                    links
                  }
                  ... on SuperOps_CtaFeature {
                    __typename
                    ctaBox {
                      type
                      theme
                      customBackgroundColor
                      heading {
                        html
                      }
                      description {
                        html
                      }
                      primaryButtonText
                      primaryButtonLink
                      secondaryButtonText
                      secondaryButtonLink
                    }
                  }
                }
              }
            }
          }
        `}
        render={data => (
          <>
            {data.SuperOps.pages.map(page => {
              const { seo, newField, subtitle } = page
              const hero = newField[0]
              const WhyPartner = newField[1]
              //   let navBgColor = hero.backgroundColor
              //   navBgColor = navBgColor.slice(
              //     navBgColor.indexOf("#"),
              //     navBgColor.indexOf("#") + 7
              //   )

              return (
                <div className="tech-partner partners">
                  <Frame seo={seo} newButton>
                    <header>
                      <Navigation
                        // color={navBgColor}
                        page="About us"
                        dskScroll={450}
                        newButton
                      />
                    </header>

                    <div>
                      <PopUpForm
                        visibility={popupVisibility}
                        togglePopup={val => {
                          setPopupVisibility(val)
                        }}
                        formData={formdata}
                        endpoint={process.env.HUBSPOT_PARTNERSHIP_ENDPOINT}
                        url={process.env.HUBSPOT_PARTNERSHIP_URL}
                        formName="Partnership form - Superops"
                        formTitle="Become a SuperOps Partner"
                        successMsg="Submitted successfully!"
                        additionalFormProps={{ IpStackData: true }}
                      />
                    </div>

                    <Hero
                      tag={hero.tag}
                      heading={hero.heroHeading.text}
                      description={hero.heroExcerpt}
                      primaryButtonText={hero.primaryButtonText}
                      primaryButtonLink={hero.primaryButtonLink}
                      secondaryButtonText={hero.secondaryButtonText}
                      secondaryButtonLink={hero.secondaryButtonLink}
                      image={hero.heroImage.url}
                      imageMob={hero.heroImageMob?.url}
                      openPopUp={() => togglePopup()}
                      newButton
                      popupForm
                    />

                    <section className="why-partners">
                        <Container className="white-box br10 position-relative down-to-up-5">
                          <h2 className="font-roboto fw-bold text-center mb50 pt80">
                            {WhyPartner.cardName}
                          </h2>
                          <div className="points d-flex flex-wrap justify-content-center mx-auto gap-24">
                            {WhyPartner.heading.map((item, idx) => {
                              return (
                                <div className="point d-flex gap-8">
                                  <div>
                                    <SVGIcon
                                      name="greenTick"
                                      className="pink-tick"
                                    />
                                  </div>
                                  <p className="fw-600 text">{item}</p>
                                </div>
                              )
                            })}
                          </div>
                          <Row className="cards flex-wrap Layout-container mx-auto justify-content-between position-absolute">
                            {WhyPartner.text.map((item, id) => {
                              return (
                                <Link
                                  to={WhyPartner.links[id]}
                                  className="card-wrap br10 position-relative"
                                  style={{
                                    backgroundColor: `${WhyPartner.color[id].hex}`,
                                  }}
                                >
                                  <SVGIcon
                                    name="rightSlantingArr"
                                    className="slanting-arrow position-absolute"
                                  />
                                  <div className="img-wrapper mb40">
                                    <img
                                      src={WhyPartner.image[id].url}
                                      alt={item}
                                      className="w-100"
                                    />
                                  </div>
                                  <p className="p20 font-roboto fw-bold m-0 mb24">
                                    {item}
                                  </p>
                                  <p className="p16 description m-0">
                                    {WhyPartner.subtext[id]}
                                  </p>
                                </Link>
                              )
                            })}
                          </Row>
                          <Row className="d-mob">
                            <Slide delay="200">
                              <CarouselComponent
                                status
                                swipeable
                                responsive={responsiveSpeaker}
                                autoPlay={false}
                              >
                                {WhyPartner.text.map((item, id) => {
                                  return (
                                    <Link to={WhyPartner.links[id]}>
                                      <div
                                        className="card-wrap br10 position-relative"
                                        style={{
                                          backgroundColor: `${WhyPartner.color[id].hex}`,
                                        }}
                                      >
                                        <SVGIcon
                                          name="rightSlantingArr"
                                          className="slanting-arrow position-absolute"
                                        />
                                        <div className="img-wrapper mb40">
                                          <img
                                            src={WhyPartner.image[id].url}
                                            alt={item}
                                            className="w-100"
                                          />
                                        </div>
                                        <p className="p20 font-roboto fw-bold m-0 mb24">
                                          {item}
                                        </p>
                                        <p className="p16 description m-0">
                                          {WhyPartner.subtext[id]}
                                        </p>
                                      </div>
                                    </Link>
                                  )
                                })}
                              </CarouselComponent>
                            </Slide>
                          </Row>
                        </Container>
                    </section>

                    <section>
                      <Slide delay="200">
                        <CTA
                          data={[newField[2].ctaBox]}
                          className="Layout-container"
                          maxHeadingWidth="600px"
                          lgLeft={7}
                          lgRight={4}
                          rightStyles={{ alignItems: "center" }}
                          newButton
                          newDesign
                          openPopUp={() => togglePopup()}
                        />
                      </Slide>
                    </section>
                  </Frame>
                </div>
              )
            })}
          </>
        )}
      />
    </>
  )
}

export default Partners
